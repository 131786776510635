import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Http requests are queries by default, but you may need to upgrade to an update in some cases. This example shows how to cache those results so your users can reap the performance benefits!`}</p>
    <p>{`Code for this example lives at `}<a parentName="p" {...{
        "href": "https://github.com/krpeacock/cache-example"
      }}>{`https://github.com/krpeacock/cache-example`}</a>{`.`}</p>
    <h2 {...{
      "id": "overview"
    }}><a parentName="h2" {...{
        "href": "#overview"
      }}>{`Overview`}</a></h2>
    <p>{`These may be features you are unfamiliar with. The `}<inlineCode parentName="p">{`http_request`}</inlineCode>{` API is how the asset canister can deliver assets over HTTPS to serve websites. The boundary node serves requests to a canister's `}<inlineCode parentName="p">{`http_request`}</inlineCode>{` endpoint if it's available as a query.`}</p>
    <p>{`If the request requires an update, for any number of reasons, you can return `}<inlineCode parentName="p">{`upgrade = ?true`}</inlineCode>{` in the payload to upgrade the request. According to the `}<a parentName="p" {...{
        "href": "https://internetcomputer.org/docs/current/references/ic-interface-spec#upgrade-to-update-calls"
      }}>{`IC Interface Spec`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`If the canister sets `}<inlineCode parentName="p">{`upgrade = opt true`}</inlineCode>{` in the `}<inlineCode parentName="p">{`HttpResponse`}</inlineCode>{` reply from `}<inlineCode parentName="p">{`http_request`}</inlineCode>{`, then the Gateway ignores all other fields of the reply. The Gateway performs an ~update~ call to `}<inlineCode parentName="p">{`http_request_update`}</inlineCode>{`, passing the same `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` record as the argument, and uses that response instead.
The value of the upgrade field returned from `}<inlineCode parentName="p">{`http_request_update`}</inlineCode>{` is ignored.`}</p>
    </blockquote>
    <p>{`This is demonstrated in the `}<a parentName="p" {...{
        "href": "https://github.com/dfinity/examples/tree/master/motoko/http_counter"
      }}>{`HTTP Counter`}</a>{` example, which also shows how to accept a POST request in order to make changes to the canister state. Some reasons you may need an update for your method might include`}</p>
    <ul>
      <li parentName="ul">{`Modifying state (updating a counter)`}</li>
      <li parentName="ul">{`Reading data from another canister`}</li>
      <li parentName="ul">{`Certifying a response`}</li>
    </ul>
    <p>{`I'll hold off on handling certified response case until `}{`[this bounty]`}{`(is completed), but let's focus on the basic case of demonstrating how the upgrade flow works.`}</p>
    <h2 {...{
      "id": "lifecycle-of-the-process"
    }}><a parentName="h2" {...{
        "href": "#lifecycle-of-the-process"
      }}>{`Lifecycle of the process`}</a></h2>
    <p>{`The request comes in to the `}<inlineCode parentName="p">{`http_request`}</inlineCode>{` field. The canister checks the cache for the full `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` entry as a key. If a hit is found, it returns it. Otherwise, it returns`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}>{`case null `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Debug`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`print`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Request was not found in cache. Upgrading to update request.\\n"`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
        status_code `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`404`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        headers `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        body `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Blob`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`fromArray`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        streaming_strategy `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` null`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
        upgrade `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`?`}</span><span parentName="code" {...{
          "className": "token boolean"
        }}>{`true`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`By returning `}<inlineCode parentName="p">{`upgrade = ?true`}</inlineCode>{`, the rest of the information is disregarded. The request is sent back to the boundary node, which re-requests the same `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{` again as an update call, hitting the `}<inlineCode parentName="p">{`http_request_update`}</inlineCode>{` method.`}</p>
    <p>{`For this example, all the processing we need is to craft the body of the response as Text, based on all the information contained in the request, plus a timestamp. We add it to the cache, and then return the response.`}</p>
    <pre {...{
      "className": "language-rust"
    }}><code parentName="pre" {...{
        "className": "language-rust"
      }}><span parentName="code" {...{
          "className": "token class-name"
        }}>{`Debug`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`print`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token string"
        }}>{`"Storing request in cache."`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` time `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Time`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`now`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` message `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Request has been stored in cache: \\n"`}</span>{` # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"URL is: "`}</span>{` # url # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"\\n"`}</span>{` # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Method is "`}</span>{` # req`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`method # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"\\n"`}</span>{` # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Body is: "`}</span>{` # debug_show req`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`body # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"\\n"`}</span>{` # `}<span parentName="code" {...{
          "className": "token string"
        }}>{`"Timestamp is: \\n"`}</span>{` # debug_show `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Time`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`now`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`let`}</span>{` response `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`HttpResponse`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`{`}</span>{`
    status_code `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Nat16`}</span>{` `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token number"
        }}>{`200`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    headers `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`[`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`]`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    body `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token class-name"
        }}>{`Text`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`encodeUtf8`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`message`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    streaming_strategy `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` null`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
    upgrade `}<span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` null`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`}`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

cache `}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`:`}</span><span parentName="code" {...{
          "className": "token operator"
        }}>{`=`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`RBT`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span><span parentName="code" {...{
          "className": "token function"
        }}>{`put`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`(`}</span>{`cache`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` `}<span parentName="code" {...{
          "className": "token constant"
        }}>{`HTTP`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`.`}</span>{`compare_http_request`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` req`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`,`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`)`}</span><span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`

`}<span parentName="code" {...{
          "className": "token keyword"
        }}>{`return`}</span>{` response`}<span parentName="code" {...{
          "className": "token punctuation"
        }}>{`;`}</span>{`
`}</code></pre>
    <p>{`From then on, the cache is populated, and future requests will be able to work as queries over http. A slow request only needs to be handled once, and then all subsequent queries will be fast, much like a traditional architecture.`}</p>
    <h2 {...{
      "id": "next-steps"
    }}><a parentName="h2" {...{
        "href": "#next-steps"
      }}>{`Next Steps`}</a></h2>
    <p>{`To productionize a tool like this, you may want to consider some of the following strategies:`}</p>
    <ol>
      <li parentName="ol">{`Consider what your cache's key should be`}</li>
    </ol>
    <p>{`It may be simple enough to rely on the `}<inlineCode parentName="p">{`path`}</inlineCode>{` of the URL from the `}<inlineCode parentName="p">{`HttpRequest`}</inlineCode>{`. Headers for different browsers will frequently be different, but it depends on your application logic whether you may want to serve different payloads based on other components from the request.`}</p>
    <ol {...{
      "start": 2
    }}>
      <li parentName="ol">{`Cache invalidation`}</li>
    </ol>
    <p>{`Does data in your application change over time? You may want to add an expiration where the canister will upgrade a request and re-validate the response every hour, day, week, etc. You may want to add a manual call to invalidate all or part of your cache`}</p>
    <ol {...{
      "start": 3
    }}>
      <li parentName="ol">{`Certified variables`}</li>
    </ol>
    <p>{`It's currently difficult to implement certified variables in Motoko, but it will be important if you deliver the asset via icp0.io (or ic0.app for legacy canisters). If you have not implemented certification, you will have to access the assets via the `}<inlineCode parentName="p">{`.raw`}</inlineCode>{` subdomain, and it will come with less security.`}</p>
    <ol {...{
      "start": 4
    }}>
      <li parentName="ol">{`Access controls`}</li>
    </ol>
    <p>{`You may want to deliver assets via http that are only available to certain users. If you are using traditional HTTP, the caller will not be available to you, so you may need to use other strategies, such as query parameters or cookie headers to determine whether that access can be delivered. You may want to provide a different version of a `}<inlineCode parentName="p">{`"/account"`}</inlineCode>{` page based on whether the user is logged in, for example, and that might vary on the user making the request. A dynamic page like that might be something you want to exclude from the cache.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      